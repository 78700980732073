import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { styled, useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { yellow } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import { Link, useRouteMatch } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { forwardRef, memo, useState, useEffect } from 'react';
import FuseThemeSchemes from '@fuse/core/FuseThemeSchemes';
import { useSwipeable } from 'react-swipeable';
import SwipeFavourites from './swipes';

const Root = styled('div')(({ theme }) => ({
    position: 'absolute',
    // height: 80,
    right: 0,
    top: 215,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    opacity: 0.9,
    padding: 0,
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    zIndex: 999,
    color: theme.palette.getContrastText(yellow[600]),
    backgroundColor: yellow[600],
    '&:hover': {
        backgroundColor: yellow[600],
        opacity: 1
    }
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        position: 'fixed',
        width: 380,
        maxWidth: '90vw',
        backgroundColor: theme.palette.background.paper,
        top: 0,
        height: '100%',
        minHeight: '100%',
        bottom: 0,
        right: 0,
        margin: 0,
        zIndex: 1000,
        borderRadius: 0
    }
}));

const Transition = forwardRef(function Transition(props, ref) {
    const theme = useTheme();
    return <Slide direction={theme.direction === 'ltr' ? 'left' : 'right'} ref={ref} {...props} />;
});

function RightToolbox() {
    const theme = useTheme();

    const [open, setOpen] = useState(false);

    const [userFavourites, setUserFavourites] = useState(null);
    const [browserRecent, setBrowserRecent] = useState(null);

    const favourites = useSelector(({ auth }) => auth.user?.favourites);

    useEffect(() => {
        // user favs:
        const a =
            favourites &&
            favourites.map(f => {
                return { uid: f.uid, name: f.name };
            });
        setUserFavourites(a);
    }, [favourites]);

    useEffect(() => {
        // TODO: zrobic porównania bo idzie rerenderer
        const interval = setInterval(() => {
            // browser recent:
            const recentItems = JSON.parse(window.localStorage.getItem('recentSearch')) || null;
            setBrowserRecent(recentItems);
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    const handlerOptions = {
        onSwipedLeft: () => {
            return open && theme.direction === 'rtl' && handleClose();
        },
        onSwipedRight: () => {
            return open && theme.direction === 'ltr' && handleClose();
        }
    };

    const settingsHandlers = useSwipeable(handlerOptions);
    const shemesHandlers = useSwipeable(handlerOptions);

    const handleOpen = panelId => {
        setOpen(panelId);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const hideNav = useRouteMatch('/(maintenance|coming-soon)'); // on this pages hide NavBar

    if (hideNav) return <></>;

    if (!userFavourites && !browserRecent) return null;

    return (
        <>
            <Root id="fuse-settings-schemes" className="buttonWrapper">
                {userFavourites && (
                    <Button
                        className="settingsButton min-w-40 w-40 h-40 m-0"
                        onClick={() => handleOpen('settings')}
                        variant="text"
                        color="inherit"
                    >
                        <Icon className="text-20">bookmark_border</Icon>
                    </Button>
                )}
                {browserRecent && (
                    <Button
                        className="min-w-40 w-40 h-40 m-0"
                        onClick={() => handleOpen('schemes')}
                        variant="text"
                        color="inherit"
                    >
                        <Icon className="text-20">youtube_searched_for</Icon>
                    </Button>
                )}
            </Root>
            <StyledDialog
                TransitionComponent={Transition}
                aria-labelledby="settings-panel"
                aria-describedby="settings"
                open={open === 'settings'}
                keepMounted
                onClose={handleClose}
                BackdropProps={{ invisible: true }}
                classes={{
                    paper: 'shadow-lg'
                }}
                {...settingsHandlers}
            >
                <FuseScrollbars className="p-16 sm:p-32">
                    <IconButton className="fixed top-0 ltr:right-0 rtl:left-0 z-10" onClick={handleClose} size="large">
                        <Icon>close</Icon>
                    </IconButton>

                    <Typography className="mb-32 font-semibold" variant="h6">
                        Twoje ulubione firmy
                    </Typography>
                    <Typography className="mb-24 text-12 italic" color="textSecondary">
                        * Tutaj znajdziesz firmy, które zaznaczyłeś/aś jako swoje ulubione. W każdej chwili możesz
                        edytować zawartość Ulubionych.
                        <br /> Widoczne dla Ciebie będą dopiero po zalogowaniu.
                    </Typography>
                    <SwipeFavourites items={userFavourites} swipeType="favs" onHandleClose={() => handleClose()} />
                </FuseScrollbars>
            </StyledDialog>
            <StyledDialog
                TransitionComponent={Transition}
                aria-labelledby="schemes-panel"
                aria-describedby="schemes"
                open={open === 'schemes'}
                keepMounted
                onClose={handleClose}
                BackdropProps={{ invisible: true }}
                classes={{
                    paper: 'shadow-lg'
                }}
                {...shemesHandlers}
            >
                <FuseScrollbars className="p-16 sm:p-32">
                    <IconButton className="fixed top-0 ltr:right-0 rtl:left-0 z-10" onClick={handleClose} size="large">
                        <Icon>close</Icon>
                    </IconButton>

                    <Typography className="mb-32" variant="h6">
                        Ostatnio wyszukiwane
                    </Typography>

                    <Typography className="mb-24 text-12 italic" color="textSecondary">
                        * Tutaj znajdziesz ostatnie wyszukiwania.
                    </Typography>

                    <SwipeFavourites items={browserRecent} type="recent" onHandleClose={() => handleClose()} />
                </FuseScrollbars>
            </StyledDialog>
        </>
    );
}

export default memo(RightToolbox);
