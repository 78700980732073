import { lazy } from 'react';

const RegisterPageConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/register',
            component: lazy(() => import('./RegisterPage'))
        }
    ]
};

export default RegisterPageConfig;
