import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import tokenService from 'app/services/tokenService';

export const submitRegister = createAsyncThunk('user/register', async (params, thunkAPI) => {
    // thunkAPI.dispatch(showMessage({ message: 'Course Saved', variant: 'info' }));

    return tokenService
        .createUser(params)
        .then(response => {
            return response;
        })
        .catch(errors => {
            return errors;
        });
});

const registerSlice = createSlice({
    name: 'auth/register',
    initialState: null,
    reducers: {},
    extraReducers: {
        [submitRegister.fulfilled]: (state, action) => action.payload
    }
});

export default registerSlice.reducer;
