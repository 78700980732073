import Avatar from '@mui/material/Avatar';
import Hidden from '@mui/material/Hidden';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logoutUser } from 'app/auth/store/userSlice';

function UserMenu(props) {
    const dispatch = useDispatch();
    const user = useSelector(({ auth }) => auth.user);

    const [userMenu, setUserMenu] = useState(null);

    const userMenuClick = event => {
        setUserMenu(event.currentTarget);
    };

    const userMenuClose = () => {
        setUserMenu(null);
    };

    if (!user.name)
        return (
            <Link to="/login">
                <Button className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6" color="secondary">
                    <div className="flex flex-col mx-4 items-end">
                        <Hidden mdDown>
                            <Typography component="span" className="font-semibold flex">
                                Logowanie / Rejestracja
                            </Typography>
                        </Hidden>
                        <Hidden mdUp>
                            <Icon fontSize="inherit">account_circle</Icon>
                        </Hidden>
                    </div>
                </Button>
            </Link>
        );

    return (
        <>
            <Button className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6" onClick={userMenuClick} color="secondary">
                <div className="flex flex-col mx-4 items-end">
                    <Typography component="span" className="font-semibold flex">
                        {user.name.length < 13 ? user.name.toUpperCase() : `${user.name.toUpperCase().slice(0, 13)}...`}
                    </Typography>
                    <Typography className="text-11 font-medium capitalize" color="textSecondary">
                        {user.status === 1 ? 'Konto Premium' : 'Konto darmowe'}
                    </Typography>
                </div>
            </Button>

            <Popover
                open={Boolean(userMenu)}
                anchorEl={userMenu}
                onClose={userMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                classes={{
                    paper: 'py-8'
                }}
            >
                {!user.role || user.role.length === 0 ? (
                    <>
                        <MenuItem component={Link} to="/profile" role="button" onClick={userMenuClose}>
                            <ListItemIcon className="min-w-40">
                                <Icon>account_circle</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Mój Profil" />
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                dispatch(logoutUser());
                                userMenuClose();
                            }}
                        >
                            <ListItemIcon className="min-w-40">
                                <Icon>exit_to_app</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Wyloguj" />
                        </MenuItem>
                    </>
                ) : (
                    <>
                        <MenuItem component={Link} to="/pages/profile" onClick={userMenuClose} role="button">
                            <ListItemIcon className="min-w-40">
                                <Icon>account_circle</Icon>
                            </ListItemIcon>
                            <ListItemText primary="My Profile" />
                        </MenuItem>
                        <MenuItem component={Link} to="/apps/mail" onClick={userMenuClose} role="button">
                            <ListItemIcon className="min-w-40">
                                <Icon>mail</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Inbox" />
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                dispatch(logoutUser());
                                userMenuClose();
                            }}
                        >
                            <ListItemIcon className="min-w-40">
                                <Icon>exit_to_app</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </MenuItem>
                    </>
                )}
            </Popover>
        </>
    );
}

export default UserMenu;
