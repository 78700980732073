import { styled } from '@mui/material/styles';
import _ from '@lodash';
import { yellow } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import MenuList from '@mui/material/MenuList';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { selectFlatNavigation } from 'app/store/fuse/navigationSlice';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { memo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { updateUserFavourites } from 'app/auth/store/userSlice';
import { showMessage } from 'app/store/fuse/messageSlice';

const Root = styled('div')(({ theme }) => ({
    '& .FuseSettings-formControl': {
        margin: '6px 0',
        width: '100%',
        '&:last-child': {
            marginBottom: 0
        }
    },

    '& .FuseSettings-group': {},

    '& .FuseSettings-formGroupTitle': {
        position: 'absolute',
        top: -10,
        left: 8,
        fontWeight: 600,
        padding: '0 4px',
        backgroundColor: theme.palette.background.paper
    },

    '& .FuseSettings-formGroup': {
        position: 'relative',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 2,
        padding: '12px 12px 0 12px',
        margin: '24px 0 16px 0',
        '&:first-of-type': {
            marginTop: 16
        }
    }
}));

function SwipeFavourites(props) {
    const dispatch = useDispatch();

    const container = {
        show: {
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    function handleRemoveFromFav(uid) {
        axios.delete(`https://nieplaci.pl/api/user/favourites/${uid}`).then(res => {
            if (res.data.status === 'OK') {
                dispatch(updateUserFavourites(res.data.favourites));
                dispatch(showMessage({ message: 'Usunięto z ulubionych', variant: 'info' }));
            }
            // setData(res.data);
        });
        console.log(`removeFromFav: ${uid}`);
    }

    const isFavs = () => {
        return props.swipeType === 'favs';
    };

    return (
        <Root>
            <MenuList>
                <motion.div
                    variants={container}
                    initial="hidden"
                    animate="show"
                    className={clsx('flex flex-1 flex-col')}
                >
                    {props.items &&
                        props.items.map(
                            _item =>
                                _item && (
                                    <Link
                                        key={_item.uid}
                                        to={`/company/${_item.uid}`}
                                        role="button"
                                        onClick={() => {
                                            props.onHandleClose();
                                        }}
                                    >
                                        <MenuItem>
                                            {isFavs() && (
                                                <ListItemIcon className="min-w-40">
                                                    <Icon sx={{ color: yellow[600] }}>bookmark</Icon>
                                                </ListItemIcon>
                                            )}
                                            <ListItemText
                                                className="text-ellipsis overflow-hidden"
                                                primary={_item.name}
                                            />
                                            {isFavs() && (
                                                <Tooltip
                                                    title="Kliknij aby usunąć z listy"
                                                    placement={props.variant === 'horizontal' ? 'bottom' : 'left'}
                                                >
                                                    <IconButton
                                                        onClick={ev => {
                                                            ev.preventDefault();
                                                            ev.stopPropagation();
                                                            handleRemoveFromFav(_item.uid);
                                                        }}
                                                        size="large"
                                                    >
                                                        <Icon color="secondary">delete_outline</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </MenuItem>
                                    </Link>
                                )
                        )}
                </motion.div>
            </MenuList>
        </Root>
    );
}

export default memo(SwipeFavourites);
