import Hidden from '@mui/material/Hidden';
import Paper from '@mui/material/Paper';
import { makeStyles, ThemeProvider } from '@mui/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import NavbarMobileLayout2 from 'app/fuse-layouts/layout2/components/NavbarMobileLayout2';
import { navbarCloseMobile } from 'app/store/fuse/navbarSlice';
import clsx from 'clsx';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { selectNavbarTheme } from 'app/store/fuse/settingsSlice';
import NavbarLayout2 from './NavbarLayout2';

const navbarWidth = 280;

const useStyles = makeStyles(theme => ({
    navbar: {
        height: 64,
        minHeight: 64,
        maxHeight: 64
    },
    navbarMobile: {
        width: navbarWidth,
        minWidth: navbarWidth,
        transition: theme.transitions.create(['width', 'min-width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        })
    }
}));

function NavbarWrapperLayout2(props) {
    const dispatch = useDispatch();
    const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
    const navbarTheme = useSelector(selectNavbarTheme);
    const navbar = useSelector(({ fuse }) => fuse.navbar);

    const classes = useStyles(props);

    const hideNav = useRouteMatch('/(maintenance|coming-soon)'); // on this pages hide NavBar

    if (hideNav) return <></>;

    return (
        <>
            <ThemeProvider theme={navbarTheme}>
                <NavbarLayout2 className={clsx(classes.navbar, props.className)} />

                <Hidden lgUp>
                    <SwipeableDrawer
                        anchor="right"
                        variant="temporary"
                        open={navbar.mobileOpen}
                        classes={{
                            paper: clsx(classes.navbarMobile, 'flex-col flex-auto h-full')
                        }}
                        onClose={() => dispatch(navbarCloseMobile())}
                        // eslint-disable-next-line
                        onOpen={() => {}}
                        disableSwipeToOpen
                        ModalProps={{
                            keepMounted: true // Better open performance on mobile.
                        }}
                    >
                        <NavbarMobileLayout2 />
                    </SwipeableDrawer>
                </Hidden>
            </ThemeProvider>
        </>
    );
}

export default memo(NavbarWrapperLayout2);
