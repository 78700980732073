import AppBar from '@mui/material/AppBar';
import { ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { memo } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectFooterTheme } from 'app/store/fuse/settingsSlice';
import IconButton from '@mui/material/IconButton';
import { motion } from 'framer-motion';

function FooterLayout2(props) {
    const footerTheme = useSelector(selectFooterTheme);
    const hideFooter = useRouteMatch('/(login|register|forgot-password|maintenance|mail-verified|coming-soon)'); // on this pages hide footer

    if (hideFooter) return <></>;

    return (
        <ThemeProvider theme={footerTheme}>
            <AppBar
                id="fuse-footer"
                className={clsx('relative z-20 shadow-md', props.className)}
                color="default"
                style={{ backgroundColor: footerTheme.palette.background.paper }}
            >
                <Toolbar className="min-h-48 md:min-h-64 px-8 sm:px-12 py-24 flex items-center sm:items-start flex-col sm:flex-row text-center sm:text-left overflow-x-auto max-w-xl mx-auto w-full">
                    <div className="flex flex-grow flex-shrink-0 flex-col py-12 sm:py-0">
                        <Typography variant="subtitle1">Serwis</Typography>
                        <Typography variant="caption">
                            <Link to="/about">O nas</Link>
                        </Typography>
                        <Typography variant="caption">
                            <Link to="/report">Zgłoś błąd w serwisie</Link>
                        </Typography>
                        <Typography variant="caption">
                            <Link to="/contact">Kontakt z nami</Link>
                        </Typography>
                    </div>
                    <div className="flex flex-grow flex-shrink-0 flex-col py-12 sm:py-0">
                        <Typography variant="subtitle1">Informacje</Typography>
                        <Typography variant="caption">
                            <Link to="/debtor">Dla dłużników</Link>
                        </Typography>
                        <Typography variant="caption">
                            <Link to="/partnership">Wpółpraca z nami</Link>
                        </Typography>
                        {/* <Typography variant="caption">
                            <Link to="/other">Z kim współpracujemy</Link>
                        </Typography>
                        <Typography variant="caption">
                            <Link to="/terms">Podstawa prawna</Link>
                        </Typography> */}
                    </div>
                    <div className="flex flex-grow flex-shrink-0 flex-col py-12 sm:py-0">
                        <Typography variant="subtitle1">Dokumenty</Typography>
                        <Typography variant="caption">
                            <Link to="/terms">Regulamin Świadczenia Usług</Link>
                        </Typography>
                        <Typography variant="caption">
                            <Link to="/privacy">Polityka prywatności</Link>
                        </Typography>
                        <Typography variant="caption">
                            <Link to="/cookies">Polityka Cookies</Link>
                        </Typography>
                        {/* <Typography variant="caption">
                            <Link to="/legal">Umowa Powierzenia Przetwarzania Danych</Link>
                        </Typography> */}
                    </div>
                    <div className="flex flex-grow flex-shrink-0 flex-col py-12 sm:py-0">
                        <Typography variant="subtitle1">
                            <IconButton
                                className="w-48 h-48 px-4"
                                component={motion.a}
                                // variants={item}
                                href="/"
                                role="button"
                            >
                                <img src="assets/images/logos/fuse.svg" alt="nieplaci.pl" width="32" />
                            </IconButton>{' '}
                            nieplaci.pl
                        </Typography>
                        <Typography variant="caption" className="pl-20">
                            runlevel.pl
                        </Typography>
                        <Typography variant="caption" className="pl-20">
                            ul. Chmielna 2/31
                        </Typography>
                        <Typography variant="caption" className="pl-20">
                            00-020 Warszawa
                        </Typography>
                    </div>
                </Toolbar>
                <Toolbar className="container min-h-48 md:min-h-64 px-8 sm:px-6 py-12 flex items-center overflow-x-auto text-center">
                    <div className="flex flex-grow flex-shrink-0 flex-col">
                        <Typography variant="caption">
                            nieplaci.pl © 2022 (ver.{process.env.REACT_APP_VERSION})
                            <br /> Sprawdź czy Twój kontrahent płaci swoje zobowiązania.{' '}
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    );
}

export default memo(FooterLayout2);
