import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import _ from '@lodash';
import { Link } from 'react-router-dom';

function NavbarLoginButton(props) {
    return (
        <IconButton className={props.className} component={Link} to="/login" color="secondary" disableRipple>
            {props.children}
        </IconButton>
    );
}

NavbarLoginButton.defaultProps = {
    children: <Icon fontSize="inherit">account_circle</Icon>
};

export default NavbarLoginButton;
