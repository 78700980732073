import QuickPanel from 'app/fuse-layouts/shared-components/quickPanel/QuickPanel';
import { memo } from 'react';

function RightSideLayout3() {
    return (
        <>
            <QuickPanel />
        </>
    );
}

export default memo(RightSideLayout3);
