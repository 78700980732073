import Hidden from '@mui/material/Hidden';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { makeStyles } from '@mui/styles';
import Logo from 'app/fuse-layouts/shared-components/Logo';
import Navigation from 'app/fuse-layouts/shared-components/Navigation';
import NavbarToggleButton from 'app/fuse-layouts/shared-components/NavbarToggleButton';
import NavbarLoginButton from 'app/fuse-layouts/shared-components/NavbarLoginButton';
import UserMenu from 'app/fuse-layouts/shared-components/UserMenu';
import { Link } from 'react-router-dom';

import clsx from 'clsx';
import { memo } from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary
    }
}));

function NavbarLayout2(props) {
    const classes = useStyles(props);

    return (
        <div className={clsx('w-full shadow-md', classes.root, props.className)}>
            <div
                className={clsx(
                    'flex flex-auto justify-between items-center w-full h-full container p-0 lg:px-24 z-20'
                )}
            >
                <div className="flex flex-shrink-0 items-center px-8">
                    <Link role="button" to="/">
                        <Logo />
                    </Link>
                </div>

                <div className="flex flex-shrink-0 flex-end items-center px-8">
                    <Hidden mdDown>
                        <Navigation layout="horizontal" />
                    </Hidden>
                    {/* <NavbarLoginButton className="w-40 h-40 p-0 mx-0 sm:mx-8" /> */}
                    <UserMenu />
                    <Hidden mdUp>
                        <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                    </Hidden>
                </div>
            </div>
        </div>
    );
}

export default memo(NavbarLayout2);
