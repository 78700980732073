import { makeStyles } from '@mui/styles';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useDispatch, useSelector } from 'react-redux';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FolderIcon from '@mui/icons-material/Folder';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import Search from '../components/search';

const useStyles = makeStyles(theme => ({
    header: {
        background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
        color: theme.palette.primary.contrastText
    },
    price: {
        backgroundColor: theme.palette.primary[600],
        color: theme.palette.getContrastText(theme.palette.primary[600])
    },
    price_promo: {
        backgroundColor: 'red',
        color: theme.palette.getContrastText(theme.palette.primary[600])
    },
    panel: {
        margin: 0,
        border: 'none',
        '&:before': {
            display: 'none'
        },
        '&:first-child': {
            borderRadius: '20px 20px 0 0'
        },
        '&:last-child': {
            borderRadius: '0 0 20px 20px'
        },
        '&$expanded': {
            margin: 'auto'
        }
    },
    pricebadge: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.getContrastText(theme.palette.error.main)
    },
    hltext: {
        color: theme.palette.secondary.main
    },
    expanded: {}
}));

function HomePage() {
    const dispatch = useDispatch();
    const classes = useStyles();

    const container = {
        show: {
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const item = {
        hidden: { opacity: 0, y: 100 },
        show: { opacity: 1, y: 0 }
    };

    const openPromoInfo = () => {
        dispatch(
            openDialog({
                children: (
                    <>
                        <DialogTitle id="alert-dialog-title">Korzystasz z Promocji powitalnej.</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <b>Gratulujemy!</b> Korzystasz z naszej promocji powitalnej. Przez 2 miesiące od
                                rejestracji zupełnie za darmo będziesz użytkownikiem pakietu Premium.
                            </DialogContentText>
                            <DialogContentText id="alert-dialog-description" className="mt-6 text-12">
                                W czasie promocji nie pobieramy żadnych opłat, nie musisz podawać żadnych danych kart
                                kredytowych. Po okresie promocji Twoje konto stanie się kontem darmowym.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Link to="/promo">
                                <Button onClick={() => dispatch(closeDialog())}>Wiecej informacji</Button>
                            </Link>
                            <Link to="/register">
                                <Button color="secondary" onClick={() => dispatch(closeDialog())} autoFocus>
                                    Zakładam konto
                                </Button>
                            </Link>
                        </DialogActions>
                    </>
                )
            })
        );
    };

    return (
        <div className="w-full flex flex-col flex-auto">
            <div
                className={clsx(
                    classes.header,
                    'flex flex-col flex-shrink-0 items-center justify-center text-center p-16 sm:p-32'
                )}
            >
                <Search />
            </div>

            <div className="flex flex-col flex-1 flex-shrink-0  mx-auto px-16 sm:px-24 py-24 sm:py-32">
                <div className="flex flex-col items-center py-36 text-center sm:ltr:text-left sm:rtl:text-right max-w-xl mx-auto">
                    <Typography variant="h4" className="pb-32 font-medium">
                        Jak to działa ?
                    </Typography>

                    <div className="flex flex-wrap w-full">
                        <div className="w-full p-24">
                            <Typography className="text-16 mb-12" color="textSecondary">
                                Sprawdź opinie o swoim kontrahencie i zminimalizuj ryzyko finansowych strat. Firmy
                                zalegające z płatnościami mogą trafić do bazy <Link to="/">nieplaci.pl</Link> i&nbsp;być
                                przestrogą przed nieuczciwymi, niewypłacalnymi kontrahentami oraz firmami z problemami
                                finansowymi.
                            </Typography>
                            <Typography variant="h5" className=" mt-48 text-center" color="textSecondary">
                                Wystarczy, że podasz NIP lub REGON, a w czasie rzeczywistym:
                            </Typography>
                            <List>
                                <ListItem color="textSecondary">
                                    <ListItemIcon className="hidden sm:block" style={{ minWidth: '40px' }}>
                                        <img
                                            className=" w-24 h-24 logo-icon"
                                            src="assets/images/logos/fuse.svg"
                                            alt="logo"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Sprawdzisz opinie o dowolnej firmie"
                                        primaryTypographyProps={{
                                            color: 'textSecondary',
                                            variant: 'body2',
                                            className: 'text-16 text-center sm:text-left'
                                        }}
                                    />
                                </ListItem>
                                <ListItem color="textSecondary">
                                    <ListItemIcon className="hidden sm:block" style={{ minWidth: '40px' }}>
                                        <img
                                            className=" w-24 h-24 logo-icon"
                                            src="assets/images/logos/fuse.svg"
                                            alt="logo"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Upewnisz się, że firma, z którą współpracujesz, płaci bez opóźnień"
                                        primaryTypographyProps={{
                                            color: 'textSecondary',
                                            variant: 'body2',
                                            className: 'text-16 text-center sm:text-left'
                                        }}
                                    />
                                </ListItem>
                                <ListItem color="textSecondary">
                                    <ListItemIcon className="hidden sm:block" style={{ minWidth: '40px' }}>
                                        <img
                                            className=" w-24 h-24 logo-icon"
                                            src="assets/images/logos/fuse.svg"
                                            alt="logo"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Potwierdzisz wiarygodność finansową Twojego kontrahenta"
                                        primaryTypographyProps={{
                                            color: 'textSecondary',
                                            variant: 'body2',
                                            className: 'text-16 text-center sm:text-left'
                                        }}
                                    />
                                </ListItem>
                                <ListItem color="textSecondary">
                                    <ListItemIcon className="hidden sm:block" style={{ minWidth: '40px' }}>
                                        <img
                                            className=" w-24 h-24 logo-icon"
                                            src="assets/images/logos/fuse.svg"
                                            alt="logo"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="W łatwy sposób dodasz opinie o swoim kontrahencie"
                                        primaryTypographyProps={{
                                            color: 'textSecondary',
                                            variant: 'body2',
                                            className: 'text-16 text-center sm:text-left'
                                        }}
                                    />
                                </ListItem>
                                <ListItem color="textSecondary">
                                    <ListItemIcon className="hidden sm:block" style={{ minWidth: '40px' }}>
                                        <img
                                            className=" w-24 h-24 logo-icon"
                                            src="assets/images/logos/fuse.svg"
                                            alt="logo"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Poznasz szczegóły zobowiązań"
                                        primaryTypographyProps={{
                                            color: 'textSecondary',
                                            variant: 'body2',
                                            className: 'text-16 text-center sm:text-left'
                                        }}
                                    />
                                </ListItem>
                                <ListItem color="textSecondary">
                                    <ListItemIcon className="hidden sm:block" style={{ minWidth: '40px' }}>
                                        <img
                                            className=" w-24 h-24 logo-icon"
                                            src="assets/images/logos/fuse.svg"
                                            alt="logo"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Skontaktujesz się z kontrahentem za pomocą naszej platformy"
                                        primaryTypographyProps={{
                                            color: 'textSecondary',
                                            variant: 'body2',
                                            className: 'text-16 text-center sm:text-left'
                                        }}
                                    />
                                </ListItem>
                                <ListItem color="textSecondary">
                                    <ListItemIcon className="hidden sm:block" style={{ minWidth: '40px' }}>
                                        <img
                                            className=" w-24 h-24 logo-icon"
                                            src="assets/images/logos/fuse.svg"
                                            alt="logo"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Dostaniesz powiadomienie e-mail, gdy tylko pojawi się negatywna opinia o Twojej firmie"
                                        primaryTypographyProps={{
                                            color: 'textSecondary',
                                            variant: 'body2',
                                            className: 'text-16 text-center sm:text-left'
                                        }}
                                    />
                                </ListItem>
                                <ListItem color="textSecondary">
                                    <ListItemIcon className="hidden sm:block" style={{ minWidth: '40px' }}>
                                        <img
                                            className=" w-24 h-24 logo-icon"
                                            src="assets/images/logos/fuse.svg"
                                            alt="logo"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Otrzymasz wsparcie prawników na preferencyjnych warunkach"
                                        primaryTypographyProps={{
                                            color: 'textSecondary',
                                            variant: 'body2',
                                            className: 'text-16 text-center sm:text-left'
                                        }}
                                    />
                                </ListItem>
                            </List>

                            <Typography variant="h5" className="mt-48 mb-0 text-center" color="textSecondary">
                                Wybierz odpowiedni pakiet i&nbsp;nie daj się oszukać nieuczciwemu kontrahentowi
                            </Typography>
                        </div>
                    </div>
                </div>

                <motion.div
                    variants={container}
                    initial="hidden"
                    animate="show"
                    className="flex items-center justify-center flex-wrap"
                >
                    <motion.div variants={item} className="w-full max-w-320 sm:w-1/3 p-12">
                        <Card className="relative rounded-16">
                            <div className="p-32 text-center">
                                <Typography className="text-32 font-semibold">Darmowe</Typography>
                                <Typography color="textSecondary" className="text-16 font-normal">
                                    Sprawdź jak to działa
                                </Typography>
                            </div>

                            <CardContent className="text-center p-0">
                                <div className={clsx(classes.price, 'flex items-end justify-center py-16 px-32')}>
                                    <div className="flex justify-center">
                                        <Typography color="inherit" className="font-normal">
                                            PLN
                                        </Typography>
                                        <Typography
                                            color="inherit"
                                            className="text-32 mx-4  tracking-tight font-semibold leading-none"
                                        >
                                            0
                                        </Typography>
                                    </div>
                                    <Typography color="inherit" className="mx-4 font-normal">
                                        użytkownik / miesiąc
                                    </Typography>
                                </div>

                                <div className="flex flex-col p-32">
                                    <Typography color="textSecondary" className="mb-16">
                                        Podgląd podstawowych danych o&nbsp;firmie
                                    </Typography>
                                    <Typography color="textSecondary" className="mb-16">
                                        Zamieszczanie opinii o&nbsp;dowolnej firmie na temat jej kultury płatniczej
                                    </Typography>
                                    <Typography color="textSecondary" className="mb-16">
                                        Publikowanie informacji o&nbsp;posiadanej wierzytelności w&nbsp;stosunku do
                                        dłużnika{' '}
                                    </Typography>
                                    {/* <Typography color="textSecondary" className="mb-16">
                                        Zarządzanie wierzytelnościami
                                    </Typography> */}
                                    <Typography color="textSecondary" className="mb-16">
                                        Sprawdzanie opinii o&nbsp;własnej firmie
                                    </Typography>
                                    <Typography color="textSecondary">24 / 7 Pomoc naszych specjalistów </Typography>
                                </div>
                            </CardContent>

                            <div className="flex flex-col items-center justify-center pb-32 px-32">
                                <Button variant="contained" className="w-full" onClick={openPromoInfo}>
                                    Zacznij korzystać
                                </Button>
                                <Typography color="textSecondary" className="mt-16">
                                    Całkowicie za darmo
                                </Typography>
                            </div>
                        </Card>
                    </motion.div>

                    <motion.div variants={item} className="w-full max-w-320 sm:w-1/3 p-12">
                        <Card className="relative rounded-16" raised>
                            <div className="absolute top-0 inset-x-0 flex justify-center">
                                <div className={clsx(classes.pricebadge, 'py-4 px-8 rounded-b-4')}>
                                    <Typography className="text-12 font-semibold tracking-tight" color="inherit">
                                        NAJCZĘŚCEJ WYBIERANY
                                    </Typography>
                                </div>
                            </div>
                            <div className="p-32 text-center">
                                <Typography className="text-32 font-semibold">Premium</Typography>
                                <Typography color="textSecondary" className="text-16 font-normal">
                                    Zarządzanie Twoimi wierzytelnościami
                                </Typography>
                            </div>

                            <CardContent className="text-center p-0">
                                <div className={clsx(classes.price_promo, 'flex items-end justify-center py-16 px-32')}>
                                    <div className="flex justify-center">
                                        <Typography color="inherit" className="font-normal">
                                            PROMOCJA
                                        </Typography>
                                        <Typography
                                            color="inherit"
                                            className="text-32 mx-4  tracking-tight font-semibold leading-none"
                                        >
                                            0
                                        </Typography>
                                    </div>
                                    <Typography color="inherit" className="mx-4 font-normal">
                                        PLN / miesiąc
                                    </Typography>
                                </div>

                                <div className="flex flex-col p-32">
                                    <Typography color="textSecondary" className="mb-16">
                                        Wszystkie funkcjonalności pakietu bezpłatnego,{' '}
                                        <span className={clsx(classes.hltext)}>a&nbsp;dodatkowo:</span>
                                    </Typography>
                                    <Typography color="textSecondary" className="mb-16">
                                        Sprawdzanie kontrahentów
                                    </Typography>
                                    <Typography color="textSecondary" className="mb-16">
                                        Komunikacja z dłużnikiem za pomocą naszej platformy
                                    </Typography>
                                    <Typography color="textSecondary" className="mb-16">
                                        Wsparcie prawników na&nbsp;preferencyjnych warunkach
                                    </Typography>
                                    <Typography color="textSecondary" className="mb-16">
                                        Zaawansowane Raportowanie
                                    </Typography>
                                    <Typography color="textSecondary">24 / 7 Pomoc naszych specjalistów</Typography>
                                </div>
                            </CardContent>

                            <div className="flex flex-col items-center justify-center pb-32 px-32">
                                <Button
                                    // component={Link}
                                    variant="contained"
                                    color="secondary"
                                    className="w-full"
                                    onClick={openPromoInfo}
                                >
                                    Zacznij korzystać
                                </Button>
                                <Typography color="textSecondary" className="mt-16">
                                    Otrzymasz Fakturę VAT (23%)
                                </Typography>
                            </div>
                        </Card>
                    </motion.div>

                    <motion.div variants={item} className="w-full max-w-320 sm:w-1/3 p-12">
                        <Card className="relative rounded-16">
                            <div className="p-32 text-center">
                                <Typography className="text-32 font-semibold">VIP</Typography>
                                <Typography color="textSecondary" className="text-16 font-normal">
                                    Dla większych firm
                                </Typography>
                            </div>

                            <CardContent className="text-center p-0">
                                <div className={clsx(classes.price, 'flex items-end justify-center py-16 px-32')}>
                                    <div className="flex justify-center">
                                        <Typography color="inherit" className="font-normal">
                                            powyżej
                                        </Typography>

                                        <Typography
                                            color="inherit"
                                            className="text-32 mx-4  tracking-tight font-semibold leading-none"
                                        >
                                            10
                                        </Typography>
                                    </div>
                                    <Typography color="inherit" className="mx-4 font-normal">
                                        użytkowników **
                                    </Typography>
                                </div>

                                <div className="flex flex-col p-32">
                                    <Typography color="textSecondary" className="mb-16">
                                        Wszystkie funkcjonalności pakietu&nbsp;Premium,{' '}
                                        <span className={clsx(classes.hltext)}>a&nbsp;dodatkowo:</span>
                                    </Typography>
                                    <Typography color="textSecondary" className="mb-16">
                                        Możliwość przekazania sprawy do&nbsp;szybkiej windykacji
                                    </Typography>
                                    <Typography color="textSecondary" className="mb-16">
                                        Wsparcie dedykowanych prawników.
                                    </Typography>
                                    <Typography color="textSecondary" className="mb-16">
                                        Integracja API z&nbsp;własnym&nbsp;CRM
                                    </Typography>
                                    <Typography color="textSecondary">24 / 7 Pomoc naszych specjalistów</Typography>
                                </div>
                            </CardContent>

                            <div className="flex flex-col items-center justify-center pb-32 px-32">
                                <Button component={Link} to="/contact" variant="contained" className="w-full">
                                    Napisz do nas
                                </Button>
                                <Typography color="textSecondary" className="mt-16 text-center">
                                    ** Warunki współpracy negocjowane&nbsp;indywidualnie
                                </Typography>
                            </div>
                        </Card>
                    </motion.div>
                </motion.div>

                <div className="flex flex-col items-center py-72 text-center sm:ltr:text-left sm:rtl:text-right max-w-xl mx-auto">
                    <Typography variant="h4" className="pb-32 font-medium">
                        Najczęściej zadawane pytania
                    </Typography>

                    <div className="flex flex-wrap w-full">
                        <div className="w-full sm:w-1/2 p-24">
                            <Typography className="text-20 mb-8">Kto może skorzystać z portalu&nbsp;?</Typography>
                            <Typography className="text-16" color="textSecondary">
                                Każdy może skorzystać, zarówno osoby fizyczne jak i firmy. Wystarczy się{' '}
                                <Link to="/register">zarejestrować</Link> i&nbsp;potwierdzić tożsamość.
                            </Typography>
                        </div>
                        <div className="w-full sm:w-1/2 p-24">
                            <Typography className="text-20 mb-8">Ile kosztuje dostęp?</Typography>
                            <Typography className="text-16" color="textSecondary">
                                Cena za korzystanie z serwisu <Link to="/">nieplaci.pl</Link> uzależniona jest od
                                wybranego pakietu, za który pobierana jest miesięczna opłata. Obecnie oferujemy 3
                                pakiety: Darmowy, Premium, VIP.
                            </Typography>
                        </div>
                        <div className="w-full sm:w-1/2 p-24">
                            <Typography className="text-20 mb-8">Czy dostanę fakturę VAT?</Typography>
                            <Typography className="text-16" color="textSecondary">
                                Tak, domyślnie wystawiamy faktury dla wszystkich użytkowników, którzy przy rejestracji
                                konta podali numer NIP. Faktury VAT wystawiamy w cyklu miesięcznym, ostatniego dnia
                                okresu rozliczeniowego.
                            </Typography>
                        </div>
                        <div className="w-full sm:w-1/2 p-24">
                            <Typography className="text-20 mb-8">
                                Co mogę zrobić, jeśli znajdę błędy w&nbsp;informacjach na&nbsp;temat mojej firmy
                                na&nbsp;
                                <Link to="/">nieplaci.pl</Link> ?
                            </Typography>
                            <Typography className="text-16" color="textSecondary">
                                Jeśli masz jakiekolwiek zastrzeżenia lub wątpliwości odnośnie do informacji na swój
                                temat zgromadzonych na platformie <Link to="/">nieplaci.pl</Link>, skontaktuj się z
                                firmą, która przekazała te dane do nas. Nieplaci.pl jest jedynie administratorem danych
                                – dlatego nie może poprawiać niepoprawnych czy nieaktualnych informacji.
                            </Typography>
                        </div>
                        <div className="w-full sm:text-right text-center">
                            <Typography className="text-16 mb-8" color="textSecondary">
                                <Link to="/faq">zobacz więcej pytań i odpowiedzi</Link>
                            </Typography>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col items-center text-center sm:ltr:text-left sm:rtl:text-right max-w-xl mx-auto">
                    <Typography variant="h4" className="pb-32 font-medium">
                        Opinie naszych klientów
                    </Typography>
                </div>
                <div className="w-full max-w-2xl mx-auto">
                    <motion.div
                        variants={container}
                        initial="hidden"
                        animate="show"
                        className="flex items-center justify-center flex-wrap items-stretch"
                    >
                        <motion.div variants={item} className="w-full max-w-320 sm:w-1/4 p-12">
                            <Card className="relative rounded-16 h-full">
                                <div className="pt-24 pb-24 flex flex-col items-center">
                                    <Avatar src="assets/images/avatars/01.jpg" alt="dd" className="w-96 h-96" />
                                </div>

                                <CardContent className="text-center p-0">
                                    <div className="flex flex-col">
                                        <Typography color="textSecondary" className="font-medium text-14">
                                            Łukasz Nagrabski
                                        </Typography>
                                    </div>

                                    <div className="flex flex-col p-32">
                                        <Typography variant="body2" className="mb-8 text_center italic">
                                            Dzięki stronie nielaci.pl odzyskałam pieniądze od kontrahenta, który przez
                                            dłuższy czas był mi winien sporą kwotę. Polecam 100%.
                                        </Typography>
                                    </div>
                                </CardContent>
                            </Card>
                        </motion.div>
                        <motion.div variants={item} className="w-full max-w-320 sm:w-1/4 p-12">
                            <Card className="relative rounded-16 h-full">
                                <div className="pt-24 pb-24 flex flex-col items-center">
                                    <Avatar src="assets/images/avatars/02.jpg" alt="dd" className="w-96 h-96" />
                                </div>

                                <CardContent className="text-center p-0">
                                    <div className="flex flex-col">
                                        <Typography color="textSecondary" className="font-medium text-14">
                                            Dariusz Galicki
                                        </Typography>
                                    </div>

                                    <div className="flex flex-col p-32">
                                        <Typography variant="body2" className="mb-8 text_center italic">
                                            Nieterminowe płatności w branży TLS to norma, dzięki nieplaci.pl uniknęliśmy
                                            podpisania umowy&nbsp;z niewypłacalnym kontrahentem.
                                        </Typography>
                                    </div>
                                </CardContent>
                            </Card>
                        </motion.div>
                        <motion.div variants={item} className="w-full max-w-320 sm:w-1/4 p-12">
                            <Card className="relative rounded-16 h-full">
                                <div className="pt-24 pb-24 flex flex-col items-center">
                                    <Avatar src="assets/images/avatars/03.jpg" alt="dd" className="w-96 h-96" />
                                </div>

                                <CardContent className="text-center p-0">
                                    <div className="flex flex-col">
                                        <Typography color="textSecondary" className="font-medium text-14">
                                            Anna Piekutowska
                                        </Typography>
                                    </div>

                                    <div className="flex flex-col p-32">
                                        <Typography variant="body2" className="mb-8 text_center italic">
                                            Nie trzeba czekać kilka godzin na raport, wszystko sprawdzam od ręki,
                                            polecam każdemu.
                                        </Typography>
                                    </div>
                                </CardContent>
                            </Card>
                        </motion.div>
                        <motion.div variants={item} className="w-full max-w-320 sm:w-1/4 p-12">
                            <Card className="relative rounded-16 h-full">
                                <div className="pt-24 pb-24 flex flex-col items-center">
                                    <Avatar src="assets/images/avatars/04.jpg" alt="dd" className="w-96 h-96" />
                                </div>

                                <CardContent className="text-center p-0">
                                    <div className="flex flex-col">
                                        <Typography color="textSecondary" className="font-medium text-14">
                                            Maria Karmazyn
                                        </Typography>
                                    </div>

                                    <div className="flex flex-col p-32">
                                        <Typography variant="body2" className="mb-8 text_center italic">
                                            Polecam, bardzo skuteczna forma "windykacji".
                                        </Typography>
                                    </div>
                                </CardContent>
                            </Card>
                        </motion.div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
}

export default HomePage;
