import QuickPanel from 'app/fuse-layouts/shared-components/quickPanel/QuickPanel';
import { memo } from 'react';

function RightSideLayout1(props) {
    return (
        <>
            <QuickPanel />
        </>
    );
}

export default memo(RightSideLayout1);
