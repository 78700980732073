import { lazy } from 'react';
// import { Redirect } from 'react-router-dom';

const StaticPagesConfig = {
    routes: [
        {
            path: '/terms',
            component: lazy(() => import('./Terms'))
        },
        {
            path: '/privacy',
            component: lazy(() => import('./Privacy'))
        },
        {
            path: '/cookies',
            component: lazy(() => import('./Cookies'))
        },
        {
            path: '/partnership',
            component: lazy(() => import('./Partnership'))
        },
        {
            path: '/maintenance',
            component: lazy(() => import('./Maintenance'))
        },
        {
            path: '/mail-verified',
            component: lazy(() => import('./MailVerified'))
        },
        {
            path: '/coming-soon',
            component: lazy(() => import('./ComingSoon'))
        },
        {
            path: '/contact',
            component: lazy(() => import('./Contact'))
        },
        {
            path: '/about',
            component: lazy(() => import('./AboutUs'))
        },
        {
            path: '/debtor',
            component: lazy(() => import('./Debtor'))
        },
        {
            path: '/promo',
            component: lazy(() => import('./Promo'))
        },
        {
            path: '/report',
            component: lazy(() => import('./Report'))
        }
    ]
};

export default StaticPagesConfig;
