import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// export const getCourse = createAsyncThunk('academyApp/course/getCourse', async params => {
//     const response = await axios.get('/api/academy-app/course', { params });
//     const data = await response.data;
//     return data;
// });

export const getCompanyByNipRegon = createAsyncThunk('search/company', async (params, thunkAPI) => {
    // thunkAPI.dispatch(showMessage({ message: 'Course Saved', variant: 'info' }));
    const response = await axios.post('https://nieplaci.pl/api/search', { search: params });
    const data = await response.data;
    return data;
});

// export const updateCourse = createAsyncThunk(
//     'search',
//     async (_data, { getState, dispatch }) => {
//         const { id } = getState().academyApp.course;

//         const response = await axios.post('/api/academy-app/course/update', { id, ..._data });
//         const data = await response.data;

//         dispatch(showMessage({ message: 'Course Saved' }));

//         return data;
//     }
// );

const searchSlice = createSlice({
    name: 'search',
    initialState: null,
    reducers: {},
    extraReducers: {
        [getCompanyByNipRegon.fulfilled]: (state, action) => action.payload
    }
});

export default searchSlice.reducer;
