import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';
import HomePage from 'app/main/home/Home';
import LoginPageConfig from 'app/main/login/LoginPageConfig';
import RegisterPageConfig from 'app/main/register/RegisterPageConfig';
import ForgotPasswordPageConfig from 'app/main/forgot-password/ForgotPasswordPageConfig';
import Error404PageConfig from 'app/main/errors/404/Error404PageConfig';
import Error500PageConfig from 'app/main/errors/500/Error500PageConfig';
import StaticPagesConfig from 'app/main/static-pages/StaticPagesConfig';
import PaymentConfig from 'app/main/payment/PaymentConfig';
import CompanyConfig from 'app/main/company/CompanyConfig';
import CompanyAddConfig from 'app/main/company-add/CompanyAddConfig';
import FaqPage from 'app/main/faq/FaqPageConfig';
import ProfilePageConfig from 'app/main/profile/ProfilePageConfig';

const routeConfigs = [
    LoginPageConfig,
    RegisterPageConfig,
    ForgotPasswordPageConfig,
    Error404PageConfig,
    Error500PageConfig,
    StaticPagesConfig,
    PaymentConfig,
    CompanyAddConfig,
    CompanyConfig,
    FaqPage,
    ProfilePageConfig
];

const routes = [
    // if you want to make whole app auth protected by default change defaultAuth for Home:
    // ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
    // The individual route configs which has auth option won't be overridden.
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
    {
        path: '/', // Main Page (not Lazy)
        exact: true,
        component: HomePage
    },
    {
        path: '*',
        component: () => <Redirect to="/error/404" /> // Not Found
    }
];

export default routes;
