import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const CompanyConfig = {
    settings: {
        layout: {}
    },
    routes: [
        {
            path: '/company/:CompanyUid?',
            component: lazy(() => import('./Company'))
        },
        {
            path: '/apps/academy',
            component: () => <Redirect to="/company" />
        }
    ]
};

export default CompanyConfig;
