import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { navbarToggleMobile, navbarToggle } from '../../store/fuse/navbarSlice';

const useStyles = makeStyles(theme => ({
    root: {
        '&.user': {
            '& .username, & .email': {
                transition: theme.transitions.create('opacity', {
                    duration: theme.transitions.duration.shortest,
                    easing: theme.transitions.easing.easeInOut
                })
            }
        }
    },
    avatar: {
        background: theme.palette.background.default,
        transition: theme.transitions.create('all', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut
        }),
        bottom: 0,
        '& > img': {
            borderRadius: '50%'
        }
    }
}));

function UserNavbarHeader(props) {
    const dispatch = useDispatch();

    const user = useSelector(({ auth }) => auth.user);

    const classes = useStyles();

    if (user.name)
        return (
            <AppBar
                position="static"
                color="primary"
                classes={{ root: classes.root }}
                className="user relative flex flex-col items-center justify-center pt-24 pb-64 mb-32 z-0 shadow-0"
            >
                <Typography className="username text-18 whitespace-nowrap font-semibold mb-4" color="inherit">
                    {user.name.length < 13 ? user.name.toUpperCase() : `${user.name.toUpperCase().slice(0, 13)}...`}
                </Typography>
                <Typography className="email text-13 opacity-50 whitespace-nowrap font-medium" color="inherit">
                    {user.status === 1 ? 'Konto Premium' : 'Konto darmowe'}
                </Typography>
            </AppBar>
        );
    return (
        <AppBar
            position="static"
            color="primary"
            classes={{ root: classes.root }}
            className="user relative flex flex-col items-center justify-center pt-24 pb-64 mb-32 z-0 shadow-0"
        >
            <Typography className="username text-18 whitespace-nowrap font-semibold mb-12" color="inherit">
                <Link onClick={() => dispatch(navbarToggleMobile())} to="/login">
                    <Button variant="contained" color="secondary" className="w-224 mx-auto mt-16" aria-label="LOG IN">
                        Zaloguj się
                    </Button>
                </Link>
            </Typography>

            <Typography className="email text-13 opacity-50 whitespace-nowrap font-medium" color="inherit">
                Nie masz konta?
            </Typography>
            <Typography className="email text-13 opacity-50 whitespace-nowrap font-medium" color="inherit">
                <Link onClick={() => dispatch(navbarToggleMobile())} className="font-normal" to="/register">
                    Zarejestruj się
                </Link>
            </Typography>
        </AppBar>
    );
}

export default UserNavbarHeader;
