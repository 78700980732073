const settingsConfig = {
    layout: {
        style: 'layout2', // layout1 layout2 layout3
        config: {} // checkout default layout configs at app/fuse-layouts for example  app/fuse-layouts/layout1/Layout1Config.js
    },
    customScrollbars: true,
    direction: 'ltr', // rtl, ltr
    theme: {
        main: 'default',
        navbar: 'mainThemeLight',
        toolbar: 'mainThemeLight',
        footer: 'mainThemeDark'
    }
};

export default settingsConfig;
