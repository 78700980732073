const navigationConfig = [
    // {
    //     id: 'howitworks',
    //     title: 'Jak to działa ?',
    //     type: 'item',
    //     icon: 'help',
    //     url: '/profile'
    // },
    // {
    //     id: 'pricing',
    //     title: 'Cennik',
    //     type: 'item',
    //     icon: 'payment',
    //     url: '/forgot-password'
    // }
    {
        id: 'promo',
        title: 'Sprawdź naszą promocję',
        type: 'item',
        icon: 'priority_high',
        url: '/promo'
    }
];

export default navigationConfig;
