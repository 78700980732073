import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import history from '@history';

/* eslint-disable camelcase */

class TokenService extends FuseUtils.EventEmitter {
    init() {
        this.setResponseInterceptors();
        // this.setRequestInterceptors();
        this.handleAuthentication();
    }

    setResponseInterceptors = () => {
        axios.interceptors.response.use(
            response => {
                return response;
            },
            err => {
                return new Promise((resolve, reject) => {
                    if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
                        // if you ever get an unauthorized response, logout the user
                        this.emit('onAutoLogout', 'Invalid access_token');
                        this.setSession(null);
                    }
                    throw err;
                });
            }
        );
    };

    // setRequestInterceptors = () => {
    //     axios.interceptors.request.use(
    //         config => {
    //             if (this.getAccessToken() != null) config.headers.Authorization = `Bearer: ${this.getAccessToken()}`;
    //             return config;
    //         },
    //         error => {
    //             return Promise.reject(error);
    //         }
    //     );
    // };

    handleAuthentication = () => {
        const access_token = this.getAccessToken();

        if (!access_token) {
            this.emit('onNoAccessToken');

            return;
        }

        if (access_token) {
            this.setSession(access_token);
            this.emit('onAutoLogin', true);
        } else {
            this.setSession(null);
            this.emit('onAutoLogout', 'access_token expired');
        }
    };

    createUser = data => {
        return new Promise((resolve, reject) => {
            axios.post('https://nieplaci.pl/api/user/register', data).then(response => {
                if (response.status === 200) {
                    // this.setSession(response.data.access_token);
                    resolve(response.data);
                } else {
                    reject(response.data.error);
                }
            });
        });
    };

    signInWithEmailAndPassword = (email, password) => {
        return new Promise((resolve, reject) => {
            axios
                .post('https://nieplaci.pl/api/user/login', {
                    email,
                    password
                })
                .then(response => {
                    if (response.data.user) {
                        this.setSession(response.data.access_token);
                        history.push({
                            pathname: `/`
                        });
                        resolve(response.data.user);
                    } else {
                        reject(response.data.error);
                    }
                });
        });
    };

    signInWithToken = () => {
        return new Promise((resolve, reject) => {
            axios
                .get('https://nieplaci.pl/api/user/me', {
                    data: {
                        access_token: this.getAccessToken()
                    }
                })
                .then(response => {
                    if (response.data.user) {
                        // this.setSession(response.data.access_token); // tu nie oddaje ACC token
                        resolve(response.data.user);
                    } else {
                        this.logout();
                        reject(new Error('Failed to login with token.'));
                    }
                })
                .catch(error => {
                    this.logout();
                    reject(new Error('Failed to login with token.'));
                });
        });
    };

    updateUserData = user => {
        return axios.post('/api/auth/user/update', {
            user
        });
    };

    setSession = access_token => {
        if (access_token) {
            localStorage.setItem('user_token', access_token);
            axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
        } else {
            localStorage.removeItem('user_token');
            delete axios.defaults.headers.common.Authorization;
        }
    };

    logout = () => {
        this.setSession(null);
    };

    getAccessToken = () => {
        return window.localStorage.getItem('user_token');
    };
}

const instance = new TokenService();

export default instance;
