import { lazy } from 'react';

const CompanyAddConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/company/add',
            component: lazy(() => import('./CompanyAdd'))
        }
    ]
};

export default CompanyAddConfig;
