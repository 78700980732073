import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { Link } from 'react-router-dom';
import history from '@history';
import { showMessage } from 'app/store/fuse/messageSlice';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { getCompanyByNipRegon } from './store/searchSlice';

function Search(props) {
    const dispatch = useDispatch();
    const inputEl = useRef();
    const [showLoading, setShowLoading] = useState(false);
    const user = useSelector(({ auth }) => auth.user);

    let failed = 0;

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            handleSearch(inputEl.current.value);
        }
    };

    const openNotFoundInfo = () => {
        dispatch(
            openDialog({
                children: (
                    <>
                        <DialogTitle id="alert-dialog-title">Niestety, nie znaleźliśmy takiej firmy.</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Nasz zespół został o tym poinformowany i jeśli to możliwe dane zostaną uzupełnione w
                                ciągu kilku godzin.
                            </DialogContentText>
                            <DialogContentText id="alert-dialog-description" className="mt-6">
                                W każdej chwili możesz także{' '}
                                <Link onClick={() => dispatch(closeDialog())} to="/company/add">
                                    dodać
                                </Link>{' '}
                                firmę ręcznie.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Link to="/company/add">
                                <Button color="secondary" onClick={() => dispatch(closeDialog())} autoFocus>
                                    Dodaj firmę ręcznie
                                </Button>
                            </Link>
                            <Button onClick={() => dispatch(closeDialog())} color="primary" autoFocus>
                                OK
                            </Button>
                        </DialogActions>
                    </>
                )
            })
        );
    };

    const handleSearch = searchText => {
        if (!user.name) {
            dispatch(showMessage({ message: 'Aby wyszukac musisz byc zalogowany', variant: 'warning' }));
            try {
                window.dataLayer.push({
                    event: 'search_notlogged',
                    nip: searchText
                });
            } catch (error) {
                console.log('not permited');
            }
        } else if (searchText && searchText.length) {
            setShowLoading(true);
            dispatch(getCompanyByNipRegon(searchText))
                .unwrap()
                .then(originalPromiseResult => {
                    // console.log(originalPromiseResult);
                    // console.log('that was originalPromiseResult');
                    if (originalPromiseResult.status === 'OK') {
                        setShowLoading(false);
                        history.push({
                            pathname: `/company/${originalPromiseResult.data.uid}`
                        });
                    } else if (originalPromiseResult.status === 'NOT_FOUND') {
                        setShowLoading(false);
                        openNotFoundInfo();
                    } else if (originalPromiseResult.status === 'FAILED') {
                        console.log(`failed: ${failed}`);
                        if (failed > 5) {
                            failed = 0;
                            setShowLoading(false);
                            dispatch(showMessage({ message: 'Nie udało się wyszukać', variant: 'warning' })); // TODO: zmien na dialog
                            // nie dziala wyszukiwanie // FIXME: ddsd
                        } else {
                            console.log('retrying...');
                            if (!showLoading) setShowLoading(true);
                            failed += 1;
                            setTimeout(() => {
                                handleSearch(searchText);
                            }, 1000 * failed);
                        }
                    } else {
                        setShowLoading(false);
                        dispatch(showMessage({ message: originalPromiseResult.msg, variant: 'warning' }));
                    }
                })
                .catch(rejectedValueOrSerializedError => {
                    dispatch(showMessage({ message: 'Wystapil błąd', variant: 'warning' }));
                    // handle error here
                });
        } else {
            dispatch(showMessage({ message: 'Wpisz NIP lub REGON', variant: 'warning' }));
        }
    };

    return (
        <>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.3 } }}>
                <Typography color="inherit" className="mt-8 text-24 sm:text-32 sm:mt-16 mx-auto font-medium">
                    Sprawdź, czy firma płaci swoje&nbsp;zobowiązania
                </Typography>
            </motion.div>

            <motion.div initial={{ opacity: 0, y: 40 }} animate={{ opacity: 1, y: 0, transition: { delay: 0.1 } }}>
                <Typography color="inherit" variant="subtitle1" className="tracking-tight">
                    Podaj NIP lub REGON firmy aby&nbsp;wyszukać
                </Typography>
            </motion.div>
            {!showLoading && (
                <div className="flex flex-1 mt-6 sm:mt-6 w-full max-w-md">
                    <Paper className="flex items-center h-48 sm:h-56 mt-16 mr-12 w-full sm:mt-32 rounded-8 shadow">
                        <Icon color="action" className="mx-16">
                            search
                        </Icon>
                        <Input
                            placeholder="NIP lub REGON"
                            className=""
                            disableUnderline
                            fullWidth
                            inputProps={{
                                'aria-label': 'Search',
                                inputMode: 'numeric'
                            }}
                            inputRef={inputEl}
                            onKeyPress={handleKeyPress}
                        />
                    </Paper>
                    <Button
                        className="whitespace-no-wrap normal-case px-24 h-48 sm:h-56 mt-16 sm:mt-32 rounded-8"
                        variant="contained"
                        color="secondary"
                        onClick={ev => handleSearch(inputEl.current.value)}
                    >
                        <span className="flex">SZUKAJ</span>
                    </Button>
                </div>
            )}
            {showLoading && (
                <div className="flex flex-1 flex-col items-center justify-center p-24">
                    <Typography className="text-13 sm:text-20 mb-16" color="textSecondary">
                        Szukam firmy...
                    </Typography>
                    <LinearProgress className="w-192 sm:w-320 max-w-full rounded-2" color="secondary" />
                </div>
            )}
        </>
    );
}

export default Search;
